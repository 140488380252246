<template>
  <div>
    <!-- <button name="button" @click="addMarker">
            Add a marker
        </button>
        <br /> -->

    <!-- <b-table class="col-12">
            <tr>
                <th>Marker</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Tooltip</th>
                <th>Is Draggable ?</th>
                <th>Is Visible ?</th>
                <th>Location</th>
                <th>Remove</th>
            </tr>
            <tr v-for="(item, index) in markers" :key="index">D
                <td>{{ (index + 1) }}</td>
                <td>
                    <input v-model.number="item.position.lat" type="number">
                </td>
                <td>
                    <input v-model.number="item.position.lng" type="number">
                </td>
                <td>
                    <input v-model="item.tooltip" type="text">
                </td>
                <td style="text-align: center">
                    <input v-model="item.draggable" type="checkbox">
                </td>
                <td style="text-align: center">
                    <input v-model="item.visible" type="checkbox">
                </td>
                <td style="text-align: center">
                    <b-button variant="outline-primary" @click="navigatMarker(item)">
                        <feather-icon icon="CrosshairIcon" />
                    </b-button>
                </td>
                <td style="text-align: center">
                    <b-button variant="outline-danger" type="button" @click="removeMarker(index)">
                        <feather-icon icon="Trash2Icon" />
                    </b-button>
                </td>
            </tr>
        </b-table> -->

    <l-map :zoom="zoom" :center="center" :options="mapOptions" @update:center="centerUpdate">
      <l-tile-layer :url="url" :attribution="attribution" />
      <!-- Map control -->
      <l-control class="example-custom-control">
        <div class="d-flex bg-white py-1">
          <div class="mx-1">
            <b-form-checkbox @change="filterMap('inform')" v-model="handletogglemap" value="inform" class="custom-control-danger">
              <span class="text-danger">แจ้งเหตุ</span>
            </b-form-checkbox>
          </div>
          <div class="mx-1">
            <b-form-checkbox @change="filterMap('maintenance')" v-model="handletogglemap" value="maintenance" class="custom-control-warning">
              <span class="text-warning">เเจ้งซ่อม</span>
            </b-form-checkbox>
          </div>
          <div class="mx-1">
            <b-form-checkbox @change="filterMap('appeal')" v-model="handletogglemap" value="appeal" class="custom-control-info">
              <span class="text-info">ร้องเรียน</span>
            </b-form-checkbox>
          </div>
        </div>
      </l-control>
      <!-- <l-control position="bottomleft">
        <b-button @click="addMarker"> Add a marker </b-button>
      </l-control> -->

      <!-- marker -->
      <div>
        <l-marker @click="showDetall(marker.ticketId)" v-for="marker in markers" :key="marker.id" :visible.sync="marker.visible" :draggable.sync="marker.draggable" :lat-lng.sync="marker.gps" :icon="marker.icon">
          <!-- <l-icon>
            <div v-if="marker.type === 'inform'">
              <b-img src="./image/marker/inform.png" height="32" />
            </div>
            <div v-show="marker.type === 'maintenance'">
              <b-img src="./image/marker/maintenance.png" height="32" />
            </div>
            <div v-show="marker.type === 'appeal'">
              <b-img src="./image/marker/appeal.png" height="32" />
            </div>
          </l-icon> -->

          <l-popup>
            <div v-show="urlImage">
              <b-img-lazy :src="urlImage" center fluid />
            </div>
            <p style="width: 100vh">{{ marker.title }}</p>

            <div v-show="marker.link">
              <a :href="marker.link" target="_blank"> https://www.google.com/</a>
            </div>
          </l-popup>
          <!-- <l-tooltip :options="{ permanent: true, interactive: true }"> Marker {{ marker.num + 1 }} {{ marker.title }} </l-tooltip> -->
        </l-marker>
      </div>

      <!-- วงกลม -->
      <!-- <l-circle :lat-lng="circle.center" :radius="circle.radius" :color="circle.color" /> -->
    </l-map>
  </div>
</template>

<script>
  import { VueGoodTable } from 'vue-good-table';
  import 'vue-good-table/dist/vue-good-table.css';
  import { LMap, LTileLayer, LCircle, LMarker, LPopup, LControl, LTooltip, LIcon } from 'vue2-leaflet';
  import L from 'leaflet';
  import { latLng, icon, Icon } from 'leaflet';
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  });

  import 'leaflet/dist/leaflet.css';

  import dayjs from 'dayjs';
  import 'dayjs/locale/th';
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';
  import axios from 'axios';
  import API from '@/connect/config';
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const buddhistEra = require('dayjs/plugin/buddhistEra');
  dayjs.extend(buddhistEra);
  const relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);
  export default {
    name: 'Reportofincident',
    components: {
      LMap,
      LTileLayer,
      LCircle,
      LMarker,
      LPopup,
      LTooltip,
      LControl,
      LIcon,
    },

    data() {
      return {
        // table
        pageLength: 5,
        dir: false,
        columns: [
          {
            label: '#',
            field: 'num',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '10px',
          },
          {
            label: 'วันที่แจ้งเหตุ',
            field: 'registerDate',
            thClass: 'text-center',
            width: '200px',
          },
          {
            label: 'แจ้งเหตุ',
            field: 'title',
            thClass: 'text-center',
          },
          {
            label: 'ผู้ใช้งาน',
            field: 'assignee',
            thClass: 'text-center',
            width: '200px',
          },

          {
            label: 'สถานะ',
            field: 'visible',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '120px',
          },
          {
            label: 'ตำแหน่ง',
            field: 'position',
            thClass: 'text-center',
            tdClass: 'text-center',
            width: '120px',
          },
          {
            label: 'Action',
            field: 'action',
            thClass: 'text-center',
            width: '200px',
          },
        ],
        rows: [],
        searchTerm: '',
        // map data
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        mapimage: '',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',

        zoom: 11,
        center: [0, 0],
        mapOptions: {
          zoomSnap: 0.5,
        },
        blueIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
        redIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),
        orangeIcon: L.icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        }),

        markerLatLng: [14.8924654, 101.7206087, { draggable: 'true' }],
        markerHere: [0, 0, { draggable: 'true' }],
        circle: {
          center: [14.8924654, 101.7206087],
          radius: 1500,
          color: '#EA5455',
        },
        markers: [],

        lat: 0,
        lng: 0,
        urlImage: '',
        handletogglemap: ['inform', 'maintenance', 'appeal'],
      };
    },
    async mounted() {
      this.$nextTick(async () => {
        const urlParams_URL = new URLSearchParams(window.location.search);
        console.log(urlParams_URL.size);
        let refresh_token = '';
        const uId = urlParams_URL.get('uId') ? urlParams_URL.get('uId') : JSON.parse(localStorage.getItem('profile_visitor')).uId;
        const userId = urlParams_URL.get('userId') ? urlParams_URL.get('userId') : JSON.parse(localStorage.getItem('profile_visitor')).userId;
        const jwt = '';

        if (urlParams_URL.size !== 0) {
          refresh_token = urlParams_URL.get('refresh_token');
          uId = urlParams_URL.get('uId');
          userId = urlParams_URL.get('userId');
          localStorage.setItem('refresh_token', JSON.stringify(refresh_token));
          jwt = await this.$store.dispatch('getJwtDecode', refresh_token);
          localStorage.setItem('profile_visitor', JSON.stringify({ userId: userId, ...jwt }));
        } else {
          refresh_token = JSON.parse(localStorage.getItem('refresh_token'));
        }

        console.log(refresh_token);
        const url = `${API}ticket/${userId}?uId=${uId}&_page=1&_limit=100`;
        const headers = {
          Authorization: refresh_token,
        };
        const res = await axios.get(url, headers);
        let total = Math.ceil(Number(res.data.message.total) / 100);
        let res_data = [];
        for (let index1 = 1; index1 <= total; index1++) {
          const url_l = `${API}ticket/${userId}?uId=${uId}&_page=${total}&_limit=100`;
          const res_l = await axios.get(url_l, headers);
          res_data.push(...res_l.data.message.result);
        }

        console.log(res_data);
        this.markers = res_data.map((el, index) => ({
          ...el,
          num: index,
          gps: {
            lat: el.gps.latitude,
            lng: el.gps.longitude,
          },
          draggable: false,
          visible: el.status === 'open' ? true : false,
          icon: el.type === 'inform' ? this.redIcon : el.type === 'maintenance' ? this.orangeIcon : this.blueIcon,
        }));
      });
      // this.markers.push(...this.markers);
      // this.markers.push(...this.markers);
      // this.markers.push(...this.markers);
      console.log(this.markers);
      // this.$store
      //   .dispatch('getData', `ticket/${userId}?uId=${uId}`)
      //   .then(async (res) => {
      //     console.log(res);
      //     this.markers = await res.message.result.map((el, index) => ({
      //       ...el,
      //       num: index,
      //       gps: {
      //         lat: el.gps.latitude,
      //         lng: el.gps.longitude,
      //       },
      //       draggable: false,
      //       visible: el.status === 'open' ? true : false,
      //     }));
      //   })
      //   .catch((error) => {
      //     console.error('Error fetching data:', error);
      //   });

      // this.getDataTable();
      // Auto Lotacion
      navigator.geolocation.getCurrentPosition((position) => {
        console.log('17.424610, 102.788711');
        console.log(position.coords.latitude, position.coords.longitude);
        // this.markerHere = [position.coords.latitude, position.coords.longitude]
        const { latitude, longitude } = position.coords;
        // 13.66033, 100.4574967
        this.center = [latitude, longitude];
      });
    },
    methods: {
      filterMap(toggle) {
        console.log(toggle);
        console.log(this.handletogglemap);
        this.markers = this.markers.map((el) => {
          if (el.type === this.handletogglemap[0]) {
            return {
              ...el,
              visible: true,
            };
          } else if (el.type === this.handletogglemap[1]) {
            return {
              ...el,
              visible: true,
            };
          } else if (el.type === this.handletogglemap[2]) {
            return {
              ...el,
              visible: true,
            };
          } else {
            return {
              ...el,
              visible: false,
            };
          }
        });
        console.log(this.markers);
      },
      showDetall(ticketId) {
        console.log(ticketId);
        this.urlImage = '';
        // http://46.137.251.86:4000/chat/638b1f0bf822880025e292c5?ticketId=65c6ea3bb54487002136944b&_page=1&_limit=100&_sort=1
        this.$store.dispatch('getData', `chat/${JSON.parse(localStorage.getItem('profile_visitor')).userId}?ticketId=${ticketId}`).then(async (res) => {
          console.log(res.message.result[0].chatImage);
          if (res.message.result[0]?.chatImage) {
            this.$store.dispatch('getImageURL', res.message.result[0].chatImage[0]).then(async (res) => {
              console.log(res);
              this.urlImage = res;
            });
          }
        });
      },
      formatToThaiTime(date) {
        // เปลี่ยนวันที่ไทย
        return dayjs.tz(date, 'Asia/Bangkok').format('DD-MM-BBBB HH:ss');
      },
      async getDataTable() {
        console.log('userId');
        this.$store
          .dispatch('getData', `ticket/${JSON.parse(localStorage.getItem('profile_visitor')).userId}?uId=${JSON.parse(localStorage.getItem('profile_visitor')).uId}`)
          .then(async (res) => {
            this.markers = await res.message.result.map((el, index) => ({
              ...el,
              num: index,
              gps: {
                lat: el.gps.latitude,
                lng: el.gps.longitude,
              },
              draggable: false,
              visible: el.status === 'open' ? true : false,
            }));
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      },

      addMarker() {
        const newMarker = {
          position: { lat: this.lat, lng: this.lng },
          draggable: true,
          visible: true,
        };
        this.markers.push(newMarker);
      },
      centerUpdate(center) {
        const { lat, lng } = center;
        this.lat = lat;
        this.lng = lng;
      },
      navigatMarker(item) {
        this.center = [item.position.lat, item.position.lng];
      },
      removeMarker(index) {
        this.$swal({
          title: 'Are you sure?',
          // text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            });
            const remove = this.markers.filter((item) => item.num !== index);
            this.markers = remove;
          }
        });
      },
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .vue2leaflet-map {
    &.leaflet-container {
      height: 100vh;
      width: 100%;
    }
  }
  .leaflet-popup-content-wrapper .leaflet-popup-content {
    width: 40vh !important;
  }
</style>
